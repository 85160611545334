.ContentContainer {
    display: flex;
    justify-content: space-between;
}

.InnerContent {
    margin: 20px;
    border: 1px solid #898989;
    box-shadow: 5px 3px 6px 0px rgba(191,191,191,0.76);
    padding: 5px;
    text-align: center;
    flex: 1;
  }

.BookingDetails {
    flex: 1.2;
    color: var(--text-grey);
    font-size: 1.3vw;
}

.submitButton {
    background-color: var(--highlight);
    color: #FFF;
    border: 1px solid #898989;
    font-size: 1.2vw;
    padding: 10px 25px;
    cursor: pointer;
}

input[type=email] {
    font-size: 1.3vw;
    margin: 20px 0;
}

.detailSection {
    margin: 35px 5px;
}


@media only screen and (max-width: 1000px) {
    .BookingDetails {
        font-size: 1em;
    }
    .submitButton {
        font-size: 1em;
    }
    input[type=email] {
        font-size: 1em;
    }
}
