.BookingContainer {
    background-color: #FFF;
    height: 70vh;
    width: 55vw;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: grid;
    grid-template-rows: 15% 85%;
    border: 1px solid #898989;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 1400px) {
    .BookingContainer {
        width: 70vw;
    }
}

@media only screen and (max-width: 1000px) {
    .BookingContainer {
        width: 95vw;
    }
}