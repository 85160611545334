.DateBar {
    display: flex;
    justify-content: space-evenly;
}

.DateItem {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    background-color: var(--default);
    border: none;
    border-bottom: 1px solid #909090;
    font-size: 1vw;
    color: var(--text-grey);
}

.DateItem:focus {
    outline: none;
    border: 1px solid #000;
}

.DateItem:not(:first-child):not(:last-child) {
    border-left: 1px solid #909090;
    border-right: 1px solid #909090;
}


@media only screen and (max-width: 1400px) {
    .DateItem {
        font-size: 1em;
    }
}