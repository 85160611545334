.active {
    background-color: var(--highlight);
    color: #FFF
}

.disabled {
    cursor: default;
    background-color: var(--disabled);
}

button {
    cursor: pointer;
}

.simplebar-content {
    display: flex;
    flex-direction: column;
}