
.admin_signInForm {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    padding: 4% 3%;
    border: 1px solid #898989;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.admin_signInForm input {
    font-size: 1.1em;
    border: 1px solid #000;
    margin: 15px;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f7f7f7;
    border-top: 1px solid #505050;
}




.AddMeetingContainer {
    border: 1px solid #000;
    display: inline-block;
    margin: 20px
}

.AddMeetingInner {
    margin: 30px 50px
}


.AdminContainer {
    width: 80%;
    margin: 30px auto 55px auto;
    border: 1px solid #898989;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px 20px;
}




/* table styling */

.MeetingTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    table-layout: auto;
  }
  
  .MeetingTable td, .MeetingTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* .MeetingTable tr {background-color: #f2f2f2;} */
  
  
  .MeetingTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--highlight);
    color: white;
  }

  .unavailable td {
      background-color: #707070;
  }

  td.TableSelection {
      cursor: pointer;
  }

  td.TableSelection:hover {
    background-color: #ddd;
  }